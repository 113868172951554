<template>
  <v-form
    ref="form"
    class="rcc-trading-networks-form"
    v-model="valid"
    @submit.prevent="onSubmitForm"
  >
    <div class="rcc-trading-networks-form__form-body">
      <rcc-text-input
        label="Название"
        width="40%"
        v-model="form.name"
        :rules="[value => isRequiredField({ value, field: 'Название' })]"
      />
      <rcc-select
        label="Закрепленный менеджер"
        width="40%"
        :items="usersOptions"
        v-model="form.manager_id"
        :rules="[value => isRequiredField({ value, errorText: 'Выберите менеджера' })]"
      />
    </div>

    <rcc-footer-buttons :is-submit="isSubmit" @cancel-click="$router.push({ name: 'trading-networks' })"/>
  </v-form>
</template>

<script>
import TradingNetworkApi from '@/api/trading-networks'
import { getOptions } from '@/utils/get-options'
import { getUsersList } from '@/api/users'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccTextInput from 'Components/controls/rcc-text-input'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccSelect from 'Components/controls/rcc-select.vue'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccSelect
  },

  mixins: [Page, Form, TradingNetworkApi],

  data() {
    return {
      usersOptions: [],

      form: {
        name: '',
        manager_id: null
      }
    }
  },

  created() {
    getOptions.bind(this)([{
      method: getUsersList,
      target: 'usersOptions',
      errorMessage: 'Не удалось загрузить данные для менеджеров'
    }])
  }
}
</script>

<style lang="scss" scoped>
.rcc-trading-networks-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body {
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__booking-section {
    display: flex;
    gap: 20px;
  }

  &__number-input {
    flex: none;
    width: 250px;
  }

  &__form-section {
    margin: 10px 0;
  }
}
</style>
